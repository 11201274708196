import {countUp} from "./countup";
import {manipulateSlideshow1} from "./manipulateSlideshow1";
import {testimonialsSlider} from "./testimonialsSlider";
import {shortScripts} from "./shortScripts";
import {manipulateSlideshow2} from "./manipulateSlideshow2";
import {shoppingSwitch} from "./shoppingSwitch";
import {loadConfigurator} from "./custom/loadConfigurator";
import {variantSelect} from "./variantSelect";
import {manipulateThumbs} from "./custom/manipulateThumbs";
import {itemlistSlideshow} from "./custom/itemlistSlideshow";
import {actionCardSlider} from "./custom/actionCardSlider";
import {video} from "./custom/video";
import {login} from "./custom/login";
import {trustedShops} from "./custom/trustedShops";
import {itemlistSlideshowAccount} from "./custom/itemlistSlideshowAccount";
import {initChangeAddressModal} from "../ts/changeAddress";
import {manipulateSlideshow3} from "./manipulateSlideshow3";
import {loadFormInLoadedModal} from "./custom/loadFormInLoadedModal";

window.addEventListener('load', function() {
    actionCardSlider();
    countUp();
    itemlistSlideshow();
    login();
    manipulateSlideshow1();
    manipulateSlideshow2();
    manipulateSlideshow3();
    manipulateThumbs();
    shoppingSwitch();
    shortScripts();
    testimonialsSlider();
    trustedShops();
    loadConfigurator();
    variantSelect();
    video();
    itemlistSlideshowAccount();
    initChangeAddressModal();
    loadFormInLoadedModal();
});

window.addEventListener('resize', function() {
    actionCardSlider();
    manipulateSlideshow1();
    shortScripts();
});

function checkAndHidePrices() {
    var selectBoxWrapper = document.getElementById('selectBoxWrapper');
    var prices = document.querySelector('.prices');

    // Prüfen, ob selectBoxWrapper Inhalt enthält
    var hasContent = selectBoxWrapper && selectBoxWrapper.innerHTML.trim() !== '';

    // Prüfen, ob prices sichtbar ist
    var isPricesVisible = prices && window.getComputedStyle(prices).display !== 'none';

    // Wenn beide Bedingungen erfüllt sind, prices auf display: none setzen
    if (hasContent && isPricesVisible) {
        prices.style.display = 'none';
    }
}

document.addEventListener('DOMContentLoaded', function() {
    var selectBoxWrapper = document.getElementById('selectBoxWrapper');
    var prices = document.querySelector('.prices');

    // Prüfen, ob selectBoxWrapper Inhalt enthält
    var hasContent = selectBoxWrapper && selectBoxWrapper.innerHTML.trim() !== '';

    // Prüfen, ob prices sichtbar ist
    var isPricesVisible = prices && window.getComputedStyle(prices).display !== 'none';

    // Wenn beide Bedingungen erfüllt sind, prices auf display: none setzen
    if (hasContent && isPricesVisible) {
        prices.style.display = 'none';
    }

    // Anzeigen der Daten die aus dem Konfigurator kommen
    var customizationInfo = document.querySelector('.orderItem__customizationInfo');
    var orderItemList = document.querySelector('.orderItemlist');

    if (customizationInfo && orderItemList) {
        customizationInfo.style.display = 'block'; // Setzt das Element auf display: block

        var elements = customizationInfo.innerHTML.split('<br>');

        var updatedElements = elements.map(function(element) {
            var parts = element.split(':');
            if (parts.length > 1 && parts[1].trim() === '') {
                return ''; // Entfernt das Element und das zugehörige <br>
            }
            return element;
        });

        customizationInfo.innerHTML = updatedElements.filter(Boolean).join('<br>');

        // Verschiebt das customizationInfo-Element und hängt es an orderItemlist an
        orderItemList.appendChild(customizationInfo);
    }

    // Abfrage wenn man einen indivudiellen Artikel im Warenkorb hat, ob man die Seite verlassen will
    if (document.body.classList.contains('order')) {
        const basketLink = document.querySelector('a.linkWithIcon[href="/de/basket/"]');
        const otherLinks = document.querySelectorAll('a.linkWithIcon:not([href="/de/basket/"])');

        if (!basketLink || otherLinks.length > 0) {
            // Links mit der Klasse linkWithIcon abfangen
            const linksWithIcon = document.querySelectorAll('a.linkWithIcon');
            linksWithIcon.forEach(link => {
                link.addEventListener('click', function(event) {
                    event.preventDefault(); // Verhindert das Standardverhalten des Links

                    const userConfirmed = confirm('Möchtest du den Bestellprozess wirklich verlassen? Die eingegebenen Daten, wie der Name deines Kindes, gehen damit verloren. ');

                    if (userConfirmed) {
                        window.location.href = this.href; // Leitet den Benutzer zur gewünschten Seite weiter
                    }
                });
            });
            // Links im nav__confirm--leave abfangen, außer denen mit der Klasse cookies
            const navLinks = document.querySelectorAll('.nav__confirm--leave a:not(.cookies)');
            navLinks.forEach(link => {
                link.addEventListener('click', function(event) {
                    event.preventDefault(); // Verhindert das Standardverhalten des Links

                    const userConfirmed = confirm('Möchtest du den Bestellprozess wirklich verlassen? Die eingegebenen Daten, wie der Name deines Kindes, gehen damit verloren.');

                    if (userConfirmed) {
                        window.location.href = this.href; // Leitet den Benutzer zur gewünschten Seite weiter
                    }
                });
            });
        }
    }

});
