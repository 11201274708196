function executeScripts(container) {
    const scripts = container.querySelectorAll('script');

    scripts.forEach((script) => {
        const newScript = document.createElement('script');

        // Wenn es ein externes Script ist
        if (script.src) {
            newScript.src = script.src;
            newScript.async = script.async;
            newScript.defer = script.defer;
            newScript.type = script.type || 'text/javascript';
        } else {
            // Inline-Script
            newScript.textContent = script.textContent;
        }

        document.head.appendChild(newScript);
        // Optional: danach entfernen, um "sauber" zu bleiben
        document.head.removeChild(newScript);
    });
}

export function loadFormInLoadedModal(){
    window.addEventListener("modalContentLoaded", function (e){
        const modal = e.detail.target;
        const contactform = modal.querySelector(".contactformular");
        if(contactform){
            const formWrapper = contactform.querySelector('.contactform');
            if(formWrapper){
                formWrapper.style.opacity = "";
            }
            for(let script of contactform.querySelectorAll("script")){
                script.type = "text/javascript";
            }
            executeScripts(contactform);
        }
    });
}